import deepmerge from "deepmerge";
import Lottie from "react-lottie";

export interface LottieAnimationOptionalProps extends React.HTMLAttributes<SVGElement> {
  height?: number;
  options?: Object;
  width?: number;
}

interface LottieAnimationProps extends LottieAnimationOptionalProps {
  animation: Object;
}

const LottieAnimation = ({
  animation,
  height = 200,
  options = {},
  width = 200,
  ...rest
}: LottieAnimationProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
  };
  const opts = deepmerge(defaultOptions, options);
  return <Lottie options={opts} height={height} width={width} {...rest} />;
};

export default LottieAnimation;
