import { generatePath } from "react-router-dom";

interface RouteNode {
  path: string;
  prev: RouteNode | undefined;
}
type RouteTree<T> = Omit<
  {
    [K in keyof T]: T[K] extends object ? RouteNode & Omit<RouteTree<T[K]>, "root"> : RouteNode;
  },
  "root"
>;

/**
 * Takes the route map (a nested object where each property should either be a string or an object with the field "root"), and converts it into a
 * reverse tree where each node has a reference to the parent node. This allows for a path to be built using a reference to the leaf node, by traversing
 * backwards up the tree to build the full path.
 * @param routes object containing the routes.
 * @param prev the parent node.
 * @returns a tree where each node contains a reference to the parent node and its current path.
 */
export const createRouteTree = <T extends { root: string }>(
  routes: T | string,
  prev?: RouteNode
): RouteTree<T> => {
  if (typeof routes === "string") return { path: routes, prev } as RouteTree<T>;

  const { root, ...children } = routes;
  const newRoutes = { path: root, prev };
  for (const key in children) {
    newRoutes[key] = createRouteTree(children[key], { path: root, prev });
  }
  return newRoutes as RouteTree<T>;
};

// If a field is an object, make sure it has a root property as well.
const routeMap = {
  root: "/",
  app: {
    root: "app",
    accessCode: {
      root: "access-code",
      waitlist: "waitlist",
    },
    business: {
      root: "business",
      create: {
        root: "create",
        businessName: "business-name",
        personalData: "personal-data",
        businessInfo: "business-info",
        beneficialOwner: "beneficial-owner",
        registerBeneficialOwner: "beneficial-owner-register",
        taxInfo: "tax-info",
        bankConnect: "connect-bank",
      },
    },
    dashboard: "dashboard",
    fuel: {
      root: "fuel",
      card: {
        root: "card",
        initial: {
          root: "initial",
          start: "start",
          businessName: "business-name",
          choosePrint: "choose-print",
          count: "count",
          customization: "customization",
          cardProfile: "card-profile",
          preview: "preview",
          confirmation: "confirmation",
        },
        detail: ":card_id",
      },
      manageCard: {
        root: "manageCard",
        lockUnlock: "lockUnlock",
        reportStolen: "reportStolen",
        replace: "replace",
      },
      transaction: {
        root: "transaction",
        detail: ":transaction_id",
      },
      statementHistory: "statementHistory",
    },
    itemCatalog: "lineitems",
    network: "network",
    payment: {
      root: "payment",
      transactions: "transactions",
      estimates: "estimates",
      invoices: "invoices",
      paymentsDue: "due",
      transfer: {
        root: "transfer",
        fund: "fund",
        withdraw: "withdraw",
      },
    },
    project: {
      root: "project",
      get: {
        root: ":project_id",
        contacts: "contacts",
        estimates: "estimates",
        invoices: "invoices",
        transactions: "transactions",
        fuelTransactions: "fuel-transactions",
        paymentsDue: "payments-due",
        files: "files",
        fileRequests: "file-requests",
      },
      list: "list",
    },
    settings: {
      root: "settings",
      accounting: "accounting-connect",
      businessProfile: "business-profile",
      customization: "customization",
      inviteCode: "invite-code",
      payPreferences: "payment-preferences",
      payPortal: "payment-portal",
    },
    subscription: "subscription",
    support: "support",
  },
  estimatePortal: {
    root: "estimate",
    account: {
      root: ":account_id",
      request: ":estimate_id",
    },
  },
  fees: "fees",
  filePortal: {
    root: "file",
    account: {
      root: ":account_id",
      request: ":request_id",
    },
  },
  login: {
    root: "login",
    callback: "callback",
  },
  payPortal: {
    root: "pay",
    terms: "terms",
    account: {
      root: ":account_id",
      confirm: "success",
      invoice: ":invoice_id",
    },
  },
  pricing: "pricing",
  promo: "promo",
  register: {
    root: "register",
    dca: "dca",
  },
  support: "support",
  terms: "terms",
};

export const routes = createRouteTree(routeMap);

/**
 * Gets the path of a node on the route tree from root.
 * @param route the node on the route tree OR an object containing the start and end nodes, inclusive.
 * @param includeLeadingSlash whether to include the slash at the beginning of the string
 * @returns the full path of the node.
 */
export const getPath = <T extends RouteNode, U extends RouteNode>(
  route: T | { start: T; end: U },
  includeLeadingSlash = true
): string => {
  // When start and end nodes are provided.
  if ((route as T).path === undefined) {
    const node = route as { start: T; end: U };
    if (node.start.path === node.end.path) {
      if (node.end.path === "/") return includeLeadingSlash ? "/" : "";
      return includeLeadingSlash ? `/${node.end.path}` : node.end.path;
    }
    const prev = getPath({ start: node.start, end: node.end.prev }, includeLeadingSlash);
    return `${prev}${prev || includeLeadingSlash ? "/" : ""}${node.end.path}`;
  }
  // Full path.
  const node = route as T;
  if (!node.prev) {
    return node.path === "/" ? "" : node.path;
  }
  const prev = getPath(node.prev, includeLeadingSlash);
  return `${prev}${prev || includeLeadingSlash ? "/" : ""}${node.path}`;
};

/**
 * Gets the wildcard matching route (.../*) for a given path
 * @param route the route to build the wildcard path off of OR an object containing the start and end nodes to build the path from, inclusive.
 * @param fullPath whether to build off the full path from root or just the node's path.
 * @param includeLeadingSlash whether to include the slash at the beginning of the string
 * @returns the route path with the wildcard matching route appended.
 */
export const getWildcardPath = <T extends RouteNode, U extends RouteNode>(
  route: T | { start: T; end: U },
  fullPath = false,
  includeLeadingSlash = true
) => {
  const endPath =
    (route as T).path === undefined ? (route as { start: T; end: U }).end.path : (route as T).path;
  return `${fullPath ? getPath(route, includeLeadingSlash) : endPath}/*`;
};

/**
 * Gets a route with query parameters appended.
 * @param route the route to build the path off of OR an object containing the start and end nodes to build the path from, inclusive.
 * @param params the query params to append
 * @param fullPath whether to build off the full path from root or just the node's path.
 * @param includeLeadingSlash whether to include the slash at the beginning of the string
 * @returns the route path with the query parameters appended.
 */
export const getPathWithParams = <T extends RouteNode, U extends RouteNode>(
  route: T | { start: T; end: U },
  params: Record<string, string>,
  fullPath = false,
  includeLeadingSlash = true
) => {
  const endPath =
    (route as T).path === undefined ? (route as { start: T; end: U }).end.path : (route as T).path;
  return `${fullPath ? getPath(route, includeLeadingSlash) : endPath}?${new URLSearchParams(
    params
  ).toString()}`;
};

/**
 * Replaces the colon-led variables in the route's string representation with the corresponding values from the object.
 * @param route the route node.
 * @param params the object containing the values for the variables.
 * @returns the route's string representation with the variables replaced.
 */
export const getRoute = (route: RouteNode, params: Record<string, string>): string =>
  generatePath(getPath(route), params);
