/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from '../models/Address';
import type { AddressAnalysis } from '../models/AddressAnalysis';
import type { AddressMatch } from '../models/AddressMatch';
import type { AddressSuggestion } from '../models/AddressSuggestion';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AddressService {

    /**
     * Returns autocomplete guesses for a given address
     * @param search address or partial address to search
     * @param countryCode country code to search with. Assumes US if empty
     * @param maxResults max number of results to get, default 10, max 10.
     * @returns AddressSuggestion OK
     * @throws ApiError
     */
    public static autoCompleteAddress(
        search: string,
        countryCode?: string,
        maxResults?: number,
    ): CancelablePromise<Array<AddressSuggestion>> {
        return __request({
            method: 'GET',
            path: `/v1/address-service/autocomplete`,
            query: {
                'search': search,
                'countryCode': countryCode,
                'maxResults': maxResults,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Validate and standardize a US or International Address
     * @param data Address to be validated
     * @returns AddressAnalysis OK
     * @throws ApiError
     */
    public static validateAddress(
        data: Address,
    ): CancelablePromise<Array<AddressAnalysis>> {
        return __request({
            method: 'POST',
            path: `/v1/address-service/validate`,
            body: data,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Returns autocomplete guesses for a given address
     * @param search address or partial address to search
     * @param maxResults max number of results to get, default 10, max 50.
     * @returns AddressMatch OK
     * @throws ApiError
     */
    public static publicSuggestAddress(
        search: string,
        maxResults?: number,
    ): CancelablePromise<Array<AddressMatch>> {
        return __request({
            method: 'GET',
            path: `/v1/public/address-service/autocomplete`,
            query: {
                'search': search,
                'maxResults': maxResults,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Validate and standardize an Address
     * @param data Address to be validated
     * @returns AddressAnalysis OK
     * @throws ApiError
     */
    public static publicValidateAddress(
        data: Address,
    ): CancelablePromise<Array<AddressAnalysis>> {
        return __request({
            method: 'POST',
            path: `/v1/public/address-service/validate`,
            body: data,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Returns autocomplete guesses for a given address
     * @param search address or partial address to search
     * @param maxResults max number of results to get, default 10, max 50.
     * @returns AddressMatch OK
     * @throws ApiError
     */
    public static suggestAddress(
        search: string,
        maxResults?: number,
    ): CancelablePromise<Array<AddressMatch>> {
        return __request({
            method: 'GET',
            path: `/v2/address-service/autocomplete`,
            query: {
                'search': search,
                'maxResults': maxResults,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

}