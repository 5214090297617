export enum CardStatus {
  ACTIVE = "active",
  SUSPENDED = "suspended",
  ORDER = "order",
  TERMINATED = "terminated",
  PENDING = "pending",
  ACCOUNT_SUSPENDED = "account_suspended",
}

export enum FleetAccountStatus {
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
}

export enum SuspensionStatus {
  NONE = "none",
  PAST_DUE_STRIKE_1 = "past_due_strike_1", // if they are past due 10 days
  PAST_DUE_STRIKE_2 = "past_due_strike_2", // if they are past due 90 days
  PAST_DUE_STRIKE_3 = "past_due_strike_3", // if they are past due 120 days
}
