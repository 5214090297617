import cfg, { isDeployed } from "./config";
import { useEffect } from "react";
import { usePrimaryBusinessAccount } from "./module/business/hook";
import { selectCurrentUser } from "./module/user/userSlice";
import { useAppSelector } from "./store/hooks";

export const PendoTracker = () => {
  const businessAccount = usePrimaryBusinessAccount();
  const user = useAppSelector(selectCurrentUser);
  useEffect(() => {
    if (isDeployed() && businessAccount && user) {
      const account = {
        id: businessAccount?.id || "",
        name: businessAccount?.name || "",
        is_paying: businessAccount?.subscription?.plan_id === "Essentials",
        planLevel: businessAccount?.subscription?.plan_id || "",
        creationDate: businessAccount?.created_at || "",
        environment: cfg.env === "prod" ? "prod" : "dev",
      };
      const visitor = {
        id: cfg.env === "dev" ? "dev-" + user?.id : user?.id || "",
        email: user?.email_address || "",
        full_name: user?.first_name + " " + user?.last_name || "",
        business_status: businessAccount?.status || "",
        environment: cfg.env === "prod" ? "prod" : "dev",
      };

      (function (apiKey) {
        (function (p, e, n, d, o) {
          var v, w, x, y, z;
          o = p[d] = p[d] || {};
          o._q = o._q || [];
          v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
          for (w = 0, x = v.length; w < x; ++w)
            (function (m) {
              o[m] =
                o[m] ||
                function () {
                  o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
                };
            })(v[w]);
          y = e.createElement(n);
          y.async = !0;
          y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
          z = e.getElementsByTagName(n)[0];
          z.parentNode.insertBefore(y, z);
        })(window, document, "script", "pendo");

        // This function creates visitors and accounts in Pendo
        // You will need to replace <visitor-id-goes-here> and <account-id-goes-here> with values you use in your app
        // Please use Strings, Numbers, or Bools for value types.
        pendo.initialize({
          visitor: visitor,
          account: account,
        });
      })(cfg.pendo.apiKey);
    }
  }, [businessAccount, user]);

  return null;
};
