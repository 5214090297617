import LoadingSpinner from "../../component/lottie/LoadingSpinner";
import "./app-loading.scss";

export const AppLoading = () => {
  return (
    <div className="app-loading">
      <LoadingSpinner height={400} width={400} />
    </div>
  );
};
