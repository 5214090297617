import { FlowIdentifier, Tokens } from "@okta/okta-auth-js";
import OktaSignIn, { WidgetOptions } from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/css/okta-sign-in.min.css";
import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useBodyClass } from "../../component/hook";
import { ConfigurationService } from "../../generated/openapi/services/ConfigurationService";
import { useI18n } from "../../i18n";
import { CenterLayout } from "../../layout/main/CenterLayout";
import { logSentryError } from "../../sentry";
import { BusinessConfigKey } from "../business/businessConfigSlice";
import "./okta.scss";

export interface RegistrationForm {
  firstName?: string;
  lastName?: string;
  email?: string;
}

interface OktaSignInWidgetProps {
  config: WidgetOptions;
  initial?: RegistrationForm;
  onError: (reason: any) => PromiseLike<never> | void;
  onSuccess: (value: Tokens) => Tokens | PromiseLike<Tokens> | void;
  register?: boolean;
  hideUserRegistration?: boolean;
}
const OktaSignInWidget = ({
  config,
  initial,
  onSuccess,
  onError,
  register = false,
  hideUserRegistration = true,
}: OktaSignInWidgetProps) => {
  useBodyClass("okta");
  const widgetRef = useRef();
  const [queryParams] = useSearchParams();
  const t = useI18n({ namespaces: ["module/login"], prefix: "okta" });

  useEffect(() => {
    if (!widgetRef.current) return;

    const localConfig = {
      ...config,
      otp: queryParams.get("otp"),
      state: queryParams.get("state"),
      flow: (register ? "signup" : "login") as FlowIdentifier,
    };

    const widget = new OktaSignIn(localConfig);

    widget.on("afterRender", (data) => {
      const view = data.controller;
      if (view === "mfa-verify") {
        (document.getElementsByClassName("sms-request-button")[0] as HTMLElement).click();
      } else if (view === "primary-auth") {
        if (hideUserRegistration) {
          const footerInfo = document.getElementsByClassName("footer-info")[0] as HTMLElement;
          if (!footerInfo) return;
          footerInfo.remove();
        } else {
          const signupInfo = document.getElementsByClassName("signup-info")[0] as HTMLElement;
          if (!signupInfo) return;
          signupInfo.innerHTML = `<span>${t(
            "needAccount"
          )}</span><span class="signup-link"><a href="https://apply.wexinc.com/SelfRegister?pgm=wexselectfuel" target="_blank" class="link">${t(
            "dcaApply"
          )}</a></span>.`;
          const dcaUrl = ConfigurationService.getConfigurationItem(BusinessConfigKey.DCA_URL);
          dcaUrl
            .then((response) => {
              signupInfo.innerHTML = `<span>${t(
                "needAccount"
              )}</span><span class="signup-link"><a href="${
                response.value
              }" target="_blank" class="link">${t("dcaApply")}</a></span>.`;
            })
            .catch((error) => {
              logSentryError(error);
            });
        }
      } else if (data.formName === "enroll-profile" && register) {
        initial?.email && setInputValue("email", initial?.email);
        initial?.firstName && setInputValue("firstName", initial?.firstName);
        initial?.lastName && setInputValue("lastName", initial?.lastName);
      }
    });

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError);

    return () => {
      widget.remove();
      widget.off();
    };
  }, [config, initial, queryParams, onSuccess, onError, register, hideUserRegistration, t]);

  return <CenterLayout className="okta-layout-container" dark ref={widgetRef} />;
};

export default OktaSignInWidget;

/**
 * Sets the input with the given field name to the given value, and then disables it.
 * @param fieldName userProfile.{{fieldName}}, the name field of the input.
 * @param value the value to set the input to.
 */
const setInputValue = (fieldName: string, value: string) => {
  const input = document.querySelector(
    `input[name="userProfile.${fieldName}"]`
  ) as HTMLInputElement;
  input.value = value;
  // Event is needed so that Okta doesn't think the field hasn't changed and throw a validation error.
  input.dispatchEvent(new Event("input", { bubbles: true }));
  input.disabled = true;
};
